<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0 d-flex">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Nations") }}
        </h3>
      </div>

      <div class="card-toolbar">
        <input v-model="searchFilter" class="min-w-300px form-control form-control-solid mr-4" type="text"
          :placeholder="$t('Search')">
      </div>
    </div>
    <div class="card-body detail">
      <datatable ref="datatableRef" hover :table-props="tableProps" :total="total" :options.sync="tableOptions">
        <template #[`cell.country`]="{ item }">
          <div class="d-flex align-items-center">
            <country-flag :country-iso="item.iso"></country-flag>
          </div>
        </template>
        <template #[`cell.name`]="{ item }">
          <div class="d-flex align-items-center">
            <div class="ml-3">
              <div class="font-weight-bolder" :style="{ wordBreak: 'break-word' }">{{ item?.name }}</div>
            </div>
          </div>
        </template>
        <template #[`cell.time`]="{ item }">
          <div v-if="item.secondments" class="d-flex align-items-center">
            <div class="ml-3">
              <div class="font-weight-normal">{{ item.secondments.elaboration_time }}</div>
            </div>
          </div>
        </template>
        <template #[`cell.service`]="{ item }">
          <span v-if="item.secondments" class="label label-inline font-weight-bold text-capitalize" :class="{
            'label-light-success': item.secondments.service_available, 'label-light-danger': !item.secondments.service_available
          }">
            {{ item.secondments.service_available ? $t("ACTIVE").toLowerCase() : $t("NOT ACTIVE").toLowerCase() }}
          </span>
        </template>
        <template #[`cell.start_date`]="{ item }">
          <div class="d-flex align-items-center">
            <div class="ml-3">
              {{ item.fiscal_year.start }}
            </div>
          </div>
        </template>
        <template #[`cell.end_date`]="{ item }">
          <div class="d-flex align-items-center">
            <div class="ml-3">
              {{ item.fiscal_year.end }}
            </div>
          </div>
        </template>

        <template #[`cell.actions`]="{ item }">
          <div class="d-flex">
            <b-button v-b-tooltip.hover :title="$t('View Details')"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" @click="edit(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
            </b-button>
          </div>
        </template>

      </datatable>
      <b-tooltip target="time" triggers="hover">
        {{ $t("Secondment Elaboration Days") }}
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import icons from "@/core/config/icons";
import NationsCountryService from "@/core/services/nation/nations-country.service"
import CountryFlag from "@/view/components/CountryFlag.vue";
import Datatable from "@/view/components/tables/Datatable.vue";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import { backendErrorSwal } from "@/core/helpers/swal";


export default {
  components: {
    Datatable,
    CountryFlag,
  },

  data() {
    return {
      icons,
      isLoading: false,
      itemFilter: "ALL",
      fields: [
        { key: "country", label: this.$t("Country") },
        { key: "name", label: this.$t("Name"), sortable: true },
        { key: "time", label: this.$t("ED"), thAttr: { id: "time" } },
        { key: "service", label: this.$t("Secondment Service") },
        { key: "start_date", label: this.$t("Start Date") },
        { key: "end_date", label: this.$t("End Date") },
        { key: "actions", label: this.$t("Actions") },
      ],
      items: [],
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      searchFilter: ""
    };
  },

  computed: {
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter,
        responsive: true,
        "no-sort-reset": true,
        "tbody-tr-class": this.trClass,
      };
    },
  },

  watch: {
    itemFilter() {
      this.refresh();
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Nations") },
      { title: this.$t("Manage") },
    ]);
  },

  methods: {
    itemProvider(ctx, callback) {
      NationsCountryService.getPaginatedNations({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "created",
        sortDesc: ctx.sortDesc,
        search: ctx.filter,
        fields: 'iso,name,secondments.elaboration_time,secondments.service_available,fiscal_year.start,fiscal_year.end'
      }).then((res) => {
        this.total = res.count;
        callback(res.results);
      }).catch((err) => {
        backendErrorSwal(err, this.$t("failed to load items"));
        callback([]);
      });

      return null;
    },
    refresh() {
      this.$refs.datatableRef.refresh();
    },
    edit(item) {
      this.$router.push({
        name: "detail-nation",
        params: { nationId: item.iso },
      });
    }
  }
};
</script>

<style scoped>
.gap-1 {
  gap: 1rem;
}
</style>
